import React from "react";
import { Col, Row, Modal, Card } from "antd";
import { Checked, CartItem1 } from "../../../assets/icons";




const OrderSuccess = ({
    open, 
    onCancel, 
    user, 
    orderdetails, 
    merchantDetails, 
    carts, 
    onClick, 
    totalCustomerPoints, 
    word1, 
    merchant,
    isbranchSet,
    branch_address1,
    branch_address2,
    deliveryPrice,
    custAddress,
}) => {

    const activeLoyalty = localStorage.getItem("active_loyalty");
    const loyaltyActive = activeLoyalty ? JSON.parse(activeLoyalty) : null;

    const totalOrder = (cart) => {
        if (cart && cart.length === 0 ) return 0;
        const cartItemWithVariants = cart.filter((item)=>{
          return item.variants && item.variants.length > 0;
        });
    
        const cartItemsWithCust = cart.filter((item)=>{
          return item.custom_quantities && item.custom_quantities.length > 0;
        });
    
        const cartBundle = cart.filter((item)=>{
          return item.bundle_quantity && typeof item.bundle_quantity === "object";
        });
    
        const normItem = cart.filter((item)=>{
          return item.variants && item.variants.length === 0 && item.custom_quantities && item.custom_quantities.length === 0;
        });
        
        const totalVaraiant = cartItemWithVariants.reduce((prev, curr)=>{
          return Number(prev) + (Number(curr.quantity) * Number(curr.variants[0].price));
        }, 0);
    
        const totalCust = cartItemsWithCust.reduce((prev, curr)=>{
          return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].price));
        }, 0);
    
        const totalBundle = cartBundle.reduce((prev, curr)=>{
          return Number(prev) + (Number(curr.quantity) * Number(curr.price));
        }, 0);
    
        const otherTotal = normItem.reduce((prev, curr) => {
          return prev + Number(curr.quantity) * Number(curr.price);
        }, 0); 
    
        return deliveryPrice ? otherTotal + totalCust + totalVaraiant + totalBundle + Number(deliveryPrice) : otherTotal + totalCust + totalVaraiant + totalBundle;
      };

  return (
    <Modal
        open={open}
        // open={true}
        onCancel={onCancel}
        className="checkout_modal" 
        // onOk={()=>{
        //     dispatch(clearCart());
        //     setShowInStoreOrders(false);
        // }}
        footer={null}
    >
        <div style={{textAlign: "center", marginTop: "5px", lineHeight: "24.2px"}}>
            <p
                style={{fontSize: "20px",
                fontWeight: "600"}}
            >THANKS {user?.first_name ? user?.first_name.toUpperCase() : "New User"}, {word1}</p>
        </div>
        <div 
        style={{
                textAlign: "center", 
                marginTop: "5px", 
                display: "flex", 
                flexDirection: "column"
            }}>
            <span>
                    ORDER NUMBER
            </span>
            <span
                style={{fontSize: "32px",
                fontWeight: "600"}}
            >
                #{orderdetails?.order_id}
            </span>
        </div>
        <div style={{textAlign: "center", marginTop: "5px"}}>
            <img src={Checked} alt="checked"/>
        </div>
        {deliveryPrice ?
        <div style={{textAlign: "center", margin: "10px 0"}}>
            <span
                style={{fontSize: "13px",
                fontWeight: "500",
                lineHeight: "150%"
                }}
            >
                Order Completed. Please note your order number, your item(s) will be delivered to you at {custAddress}
            </span>
        </div> 
        :<div style={{textAlign: "center", margin: "10px 0"}}>
            <span
                style={{fontSize: "13px",
                fontWeight: "500",
                lineHeight: "150%"
                }}
            >
                Order Completed. Please note your order number, pickup and pay at the till at {merchantDetails?.role?.id === 1 ? " " : " "}, {`${branch_address1 ? branch_address1 : merchantDetails?.address_line1}, ${branch_address2 ? branch_address2 : merchantDetails?.address_line2}`}
            </span>
        </div>}
        <Card 
            className="instore_card"
        >
            <div>
            <h2 style={{textAlign: "center", color: "#fff", fontSize: "14px", marginBottom: "-0.5rem"}}>ORDER SUMMARY</h2>
            </div>
            <Row>
                {orderdetails?.order_items && orderdetails?.order_items.map((cart, index)=>{
                    return <Col
                        key={index}
                        span={24}
                        lg={24}
                        md={24}
                        sm={24}
                        style={{
                            margin: "0",
                            padding: "15px 5px 0 0",
                            borderBottom: "1px solid #fff",
                        }}
                        >
                        <Row
                        
                        >
                            <Col span={8}>
                                <img style={{ height: "64px", width: "64px", borderRadius: "50%"}} src={cart.picture ? cart.picture : CartItem1} alt="cart Image"/>
                                <p>
                                    
                                </p>
                            </Col>
                            <Col
                                span={8}
                                style={{display: "flex", flexDirection: "column"}}
                            >
                                
                                    <span>{cart.name}</span>
                                    {cart.variants && cart.variants.length !== 0 && cart.variants[0].value }
                                    {cart.custom_quantities && cart.custom_quantities.length !== 0 && cart.custom_quantities[0].name }
                                    {cart.products 
                                        && cart.products.length > 0 
                                        && cart.products.map((prod,index)=>{
                                            return <span style={{display: "inline-block"}}><b>{prod.name}</b>-{cart.bundle_quantity[index]} (units)</span>
                                        }) }
                                    <span
                                    style={{fontSize: "11px", opacity: "0.3"}}>
                                        {cart.variants && cart.variants.length > 0 ? Number(cart.variants[0].price).toFixed(2) : cart.custom_quantities && cart.custom_quantities.length > 0 ?  Number(cart.custom_quantities[0].price).toFixed(2) : Number(cart.price).toFixed(2)} x {cart.quantity}
                                        </span>
                            </Col>
                            <Col 
                                span={8}
                                style={{textAlign: "right", fontSize: "13px", fontWeight: "light"}}
                            >
                                <span>
                                {cart.variants && cart.variants.length > 0 ? (
                                    Number(cart.variants[0].price) * Number(cart.quantity)
                                ).toFixed(2) : cart.custom_quantities && cart.custom_quantities.length > 0 ? (
                                    Number(cart.custom_quantities[0].price) * Number(cart.quantity)
                                ).toFixed(2) : (
                                    Number(cart.price) * Number(cart.quantity)
                                ).toFixed(2)}
                                    </span>
                            </Col>
                        </Row>
                        
                    </Col>
                    
                    
                })}
                
            </Row>
            <Row>
               {deliveryPrice && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                >
                    <p style={{margin: 0, color: "#fff"}}>Delivery Fee</p>
                    <p style={{margin: 0, color: "#fff"}}>{deliveryPrice}</p>
                </div>
               )}
            </Row>
            <div 
                style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "1rem"
                }}
            >
                <span 
                style={{ 
                    color: "#fff", 
                    fontSize: "14px"
                }}
                >TOTAL: {merchantDetails?.currency || merchant?.currency}{" "} {totalOrder(carts).toFixed(2)} </span>

                {activeLoyalty && <span style={{fontSize: "11px", opacity: "0.3"}}>
                    You will earn {" "}
                    {(totalOrder(carts) / (Number(loyaltyActive?.currency_value) / Number(loyaltyActive?.points))).toFixed(2) + "pts"}
                    {/* {totalCustomerPoints + "pts"|| 0 + "pts"} */}
                </span>}
            </div>
        </Card>
        <div style={{textAlign: "center", paddingTop: "1rem"}}>
        {/* <Link to={merchantUrl}> */}
        <p
            style={{fontSize: "12px", fontWeight: "600", margin: "0"}}
            onClick={onClick}
            className="cart_action"
            >See Your Loyalty Rewards Balance</p>
        {/* </Link> */}
        </div>
    </Modal>
  )
}

export default OrderSuccess;