import { gql } from "@apollo/client";

export const CHECK_IN = gql`
	query CheckIn($id: String, $identifier_string: String) {
		checkIn(id: $id, identifier_string: $identifier_string) {
			status
			message
			customer_id
		}
	}
`;

export const FETCH_MERCHANT_DETAILS = gql`
	query FetchMerchantDetails($uRewardsId: String) {
		fetchMerchantDetails(uRewardsId: $uRewardsId)
	}
`;

export const FETCH_MERCHANT_BY_ID = gql`
	query FetchMerchantById($merchant_id: String) {
		fetchMerchantById(merchant_id: $merchant_id)
	}
`;

export const GET_CUSTOMER_POINT_DETAILS = gql`
	query getCustomerPointDetails($customer_id: Int) {
		getCustomerPointDetails(customer_id: $customer_id){
			id
			points_value
			amount
			expiry_date
			loyalty_id
			loyalty_name
		}
	}
`;

export const LIST_PARTNERS = gql`
  query ListPartners {
    listPartners {
      id
      company_name
	  banner
      company_rep_name
      company_rep_email
      company_rep_phone
      tier {
        id
        name
        min_spending_target
        max_spending_target
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query getOrders{
    getOrders{
        id
        customer {
          first_name
          last_name
          user_id
        }
        product_id
        products {
          name
          picture
      }
      order_items 
        {
            item_name
            quantity
            total_price
        }
    
        created_at
        status
        paid_at
        paid_time
        expiry_date
	  }
  }
`;

export const GET_INSTORE_ORDERS = gql`
  query getInstoreOrders($merchant_id: Int, $branch_id: Int, $homebranch_id: Int){
    getInstoreOrders(merchant_id: $merchant_id, branch_id: $branch_id, homebranch_id: $homebranch_id){
        id
        order_id
        merchant_id
		branch_id
        customer {
          first_name
          last_name
          user_id
          phone_number
        }
        total
        processed_by
        reference_code
        order_type
        created_at
        status
        paid_at
        paid_time
        expiry_date
		order_items {
            id
			item_id
			name
			quantity
			price
			description
			picture
			created_at
			merchant_id
			service_name
			service_id
			product_type
			branch_id
			tax
			variants {
				branch_product_id
				cost_price
				created_at
				id
				price
				quantity
				type
				updated_at
				value
			}
        }
	  }
  }
`;

export const LOGIN_USER = gql`
	mutation LoginUser($phone_number: String, $password: String) {
		loginUser(phone_number: $phone_number, password: $password) {
			user_id
			merchant_id
			first_name
			last_name
			state
			phone_number
			address_line1
			email
			date_of_birth
			sex
		}
	}
`;

export const SIGNUP_USER = gql`
	mutation SignUpUser($data: NewUserInput) {
		signUpUser(data: $data) {
			id
			user_id
			merchant_id
			first_name
			last_name
			email
			password
			state
			phone_number
			address_line1
			sex
			date_of_birth
		}
	}
`;

export const CREATE_BOOKING = gql`
  mutation createBookingOnLoystar($data: appointmentInputLoystar!) {
    createBookingOnLoystar(data: $data) {
      service_name
      customer_id
      staff_name
      staff_id
      staff_email
      booking_date
      status
      payment_status
      amount_charged
      completion_time
      loyalty_id
      branch_name
      branch_id
      repeat
      repeat_frequency
      repeat_number
      service_id
      created_at
      id
    }
  }
`;

export const ADD_ORDERS = gql`
	 mutation AddOrders( $data: order! ) {
		addOrders(data: $data) {
			order_id
			customer{
				user_id
			}
			status
	 	}
	 }
`

export const GET_ORGANISATION = gql`
	 query getOrganisation($organisation_id: Int){
		getOrganisation(organisation_id: $organisation_id){
			message
            organisationDetails{
              organisation_name
              organisation_phone_number
              organisation_address
              organisation_email
              organisation_category
              organisation_currency
              organisation_country
              organisation_state
              organisation_id
              organisation_banner
			  organisation_logo
			  organisation_design {
				backgroundColor
				navColor
				organisation_id
				brandColor
				fontColor
			  }
            }
		}
	 }
`

export const CONFIRM_CUSTOMER = gql`
	 query confirmCustomer( $phone_number: String ){
		confirmCustomer( phone_number: $phone_number){
			message
		}
	 }
`

export const CONFIRM_CUSTOMER_AND_CARD = gql`
	 query confirmCustomerAndGiftCard( $phone_number: String, $id: ID ){
		confirmCustomerAndGiftCard( phone_number: $phone_number, id: $id){
			message
			giftCard {
				id
				card_number
				merchant_id
				customer_id
				amount
				organisation_id
				isActive
				created_at
				recipient_name
				sender_name
				recipient_email
				recipient_phone_number
				expiry_date
			}
			user{
				id
				email
				user_id
				phone_number
				first_name 
				last_name 
				sex
				organisation_id
				address_line1
				state
				merchant_id
			}
		}
	 }
`

export const CONFIRM_CUSTOMER_AND_CARDNUMBER = gql`
	 query confirmCustomerAndGiftCardNumber( $phone_number: String, $card_number: String ){
		confirmCustomerAndGiftCardNumber( phone_number: $phone_number, card_number: $card_number){
			message
			giftCard {
				id
				card_number
				merchant_id
				customer_id
				amount
				organisation_id
				isActive
				created_at
				recipient_name
				sender_name
				recipient_email
				recipient_phone_number
				expiry_date
			}
		}
	 }
`
export const CONFIRM_GIFT_CARD_NUMBER = gql`
	 query confirmGiftCardNumber( $card_number: String ){
		confirmGiftCardNumber( card_number: $card_number){
			message
			giftCard {
				id
				card_number
				merchant_id
				customer_id
				amount
				organisation_id
				isActive
				created_at
				recipient_name
				sender_name
				recipient_email
				recipient_phone_number
				expiry_date
			}
			user{
				id
				email
				user_id
				phone_number
				first_name 
				last_name 
				sex
				organisation_id
				address_line1
				state
				merchant_id
			}
		}
	 }
`

export const CONFIRM_GIFT_CARD_ID = gql`
	 query confirmGiftCardID( $id: String ){
		confirmGiftCardID( id: $id){
			message
			giftCard {
				id
				card_number
				merchant_id
				customer_id
				amount
				organisation_id
				isActive
				created_at
				recipient_name
				sender_name
				recipient_email
				recipient_phone_number
				expiry_date
			}
			user{
				id
				email
				user_id
				phone_number
				first_name 
				last_name 
				sex
				organisation_id
				address_line1
				state
				merchant_id
			}
		}
	 }
`

export const USE_ORGANISATION_GIFTCARD = gql`
  query useVoucher($data: topUp) {
    useVoucher(data: $data) {
      message
      giftCard_balance
    }
  }
`;

export const ADD_PARTNERS_CUST = gql`
  query verifyCustomerToMerchant ($data: verifyCust){
	verifyCustomerToMerchant (data: $data){
		message
	}
  }
`
export const UPDATE_MERCHANT_SENDERID = gql`
  query updateMerchantSenderId($uRewardsId: String, $senderId: String){
    updateMerchantSenderId(uRewardsId: $uRewardsId, senderId: $senderId){
      message
    }
  }
`
export const GET_MERCHANT_SENDERID = gql`
  query getMerchantSenderId($uRewardsId: String){
    getMerchantSenderId(uRewardsId: $uRewardsId){
      message,
      senderID
    }
  }
`

export const GET_USERS = gql`
  query getUser( $id: String ){
	getUser( id: $id ){
		message
		user{
			user_id
			id
			first_name
			email
			state
			phone_number
			sex
			address_line1
			address_line2
			country
			postal_code
			longitude
			latitude
		}
	}
  }
`

export const GET_USERS_BY_PHONE = gql`
  query getUserByPhone( $phone_number: String ){
	getUserByPhone( phone_number: $phone_number ){
		message
		user{
			id
			user_id
			first_name
			email
			state
			phone_number
			sex
			address_line1
			address_line2
			country
			postal_code
			longitude
			latitude
		}
	}
  }
`

export const UPDATE_USER = gql`
  query updateCustomerDetails( $data: users ){
	updateCustomerDetails( data: $data){
		message
		user{
			id
			user_id
			first_name
			email
			state
			phone_number
			sex
			address_line1
			address_line2
			country
			postal_code
			longitude
			latitude
		}
	}
  }
`

export const CREATE_DELIVERY = gql`
  mutation createDelivery( $data: deliveryData ){
	createDelivery( data: $data){
		message
		deliverydata{
			id
			recipient
			status
			delivery_time
			delivery_date
			delivery_amount
			order_amount
			destinationInformation
			merchant_id
			user_id
			recipient
			order_id
			orderID
			branch_id
			delivery_id
		}
	}
  }
`