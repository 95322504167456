import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { cartReducer, merchantreducer } from "./cart";
import { merchantReducer } from "./merchant";
import { orderReducer } from "./order";
import { productReducer } from "./productreducer";
import { resignReducer } from "./resign";
import { uRewardsAuthReducer } from "./uRewardsAuth";
import { branchReducer } from "./branch";


export default combineReducers({
  Auth: authReducer,
  Order: cartReducer,
  Orders: orderReducer,
  merchant: merchantreducer,
  resign: resignReducer,
  productState: productReducer,
  urewardsAuth: uRewardsAuthReducer,
  merchantState: merchantReducer,
  branch: branchReducer,
});
