export const LOADING = 'LOADING';
export const AUTH = 'AUTH';
export const UPDATE_USER= "UPDATE USER";
export const DISPATCH = 'DISPATCH';
export const REQUESTS = 'REQUESTS';
export const ORDERS = 'ORDERS';
export const PRICING = 'PRICING';
export const UPDATE_PRICINGSuccess ='UPDATE_PRICINGSuccess';
export const UPDATE_PRICINGError ='UPDATE_PRICINGError';
export const UPDATE_PRICINGRequested = 'UPDATE_PRICINGRequested';

export const DELETE_PRICINGSuccess ='DELETE_PRICINGSuccess';
export const DELETE_PRICINGError ='DELETE_PRICINGError';
export const DELETE_PRICINGRequested = 'DELETE_PRICINGRequested';


export const DELETE_ZONESuccess ='DELETE_ZONESuccess';
export const DELETE_ZONEError ='DELETE_ZONEError';
export const DELETE_ZONERequested = 'DELETE_ZONERequested';

export const CREATE_ZONESuccess ='CREATE_ZONESuccess';
export const CREATE_ZONEError ='CREATE_ZONEError';
export const CREATE_ZONERequested = 'CREATE_ZONERequested';


export const CREATE_PRICESuccess ='CREATE_PRICESuccess';
export const CREATE_PRICEError ='CREATE_PRICEError';
export const CREATE_PRICERequested = 'CREATE_PRICERequested';

export const FETCH_ZONESuccess ='FETCH_ZONESuccess';
export const FETCH_ZONEError ='FETCH_ZONEError';
export const FETCH_ZONERequested = 'FETCH_ZONERequested';


export const UPDATE_ZONESuccess ='UPDATE_ZONESuccess';
export const UPDATE_ZONEError ='UPDATE_ZONEError';
export const UPDATE_ZONERequested = 'UPDATE_ZONERequested';

export const clearErrorMessageSuccess = 'clearErrorMessageSuccess';
export const clearSuccessMessageSuccess = 'clearSuccessMessageSuccess';
export const setError = 'page Error';

export const ERROR = 'ERROR';
export const MESSAGE = 'MESSAGE';

export const ORDER_PENDING = "ORDER_PENDING";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAILURE = "ORDER_FAILURE";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const MERCHANT_ORDER_DETAILS = "MERCHANT_ORDER_DETAILS";
export const SAVE_MERCHANT_STATE = "SAVE_MERCHANT_STATE"
export const CLEAR_CART = "CLEAR_CART";
export const CREATE_ID ="CREATE_ID";
export const RESIGNIN_LOADING = "RESIGNIN_LOADING";
export const RESIGNIN_SUCCESS = "RESIGNIN_SUCCESS";
export const RESIGNIN_MERCHANTID = "RESIGNIN_MERCHANTID";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_MERCHANTID = "PRODUCT_MERCHANTID";

export const UREWARDS_AUTH = "UREWARDS_AUTH";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const CART_AUTH = "CART_AUTH";
export const NOT_CART_AUTH = "NOT_CART_AUTH";

export const INVOICE = "INVOICE";
export const INVOICEFAIL = "INVOICEFAIL";
export const INVOICENOTIFICATION = "INVOICENOTIFICATION";
export const MERCHANTLOYALTY = "MERCHANTLOYALTY";
export const USSDORDERSUCCESS = "USSDORDERSUCCESS";
export const SAVEGIFTCARDTOSTATE = "SAVEGIFTCARDTOSTATE";
export const ISOTPVERIFIED = "ISOTPVERIFIED";
export const EXPIREOTP = "EXPIREOTP";
export const CLEARCARD = "CLEARCARD";
export const SETBRANCH = "SETBRANCH";