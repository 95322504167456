import React, { useEffect, useState } from "react";
import { Button, Modal, Input, message, Spin } from "antd";
import { useSelector, connect } from "react-redux";
import { getMerchantByDispatch } from "../../../redux/actions/orders";
import { useDispatch } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import ConfirmModal from "./ConfirmModal";
import { ADD_ORDERS } from "../../../constants";
import { useMutation } from "@apollo/client";
import { getApp } from "../../../redux/actions/merchant";
import { useParams, useLocation } from "react-router-dom";
import { saveInvoice } from "../../../redux/actions/ussdActions";
import axios from "axios";
import CredpalProcessModal from "./credpal";
import { LoadingOutlined } from '@ant-design/icons';
import { sendDelivery } from "../../../redux/actions/delivery";

const AskPayment = ({
  open,
  onCancel,
  merchant,
  onClickTill,
  onClickUSSD,
  onClickGiftCard,
  setDeliveryDetails,
  paywithDelivery,
  handlePayStackPayment,
  setShowInStoreOrders,
  handleStripePayment,
  getInStoreLoading,
  isPayStack,
  isStripeEnabled,
  isCredPalEnabled,
  setOrderDetails,
  deliveryPrice,
  loading,
  sendDeliveryRequest,
  handlePostSales
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { giftcard } = useSelector((state) => state.urewardsAuth);
  const { carts } = useSelector((state) => state.Order);
  // const {
  //   success: merchantsuccess,
  //   loading: merchantloading,
  //   merchanted,
  // } = useSelector((state) => state.merchantState);
  const { merchantDetails } = useSelector((state) => state.merchant);
  const [showDelivery, setShowDelivery] = useState(false);
  const [email, setEmail] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [isUSSD, setIsUSSD] = useState(false);
  const [isInStore, setIsInStore] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [address_line1, setAddress_line1] = useState("");
  const [address_line2, setAddress_line2] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [showPayStack, setShowPayStack] = useState(false);
  const [PayStackLoading, setPayStackLoading] = useState(false);
  const [gettingPayStack, setgettingPayStack] = useState(false);
  const [ isStripeLoading, setIsstripeLoading ] = useState(false);
  const [ searchParams, setSearchParams ] = useState(null);
  const [ stripePaymentStatus, setStripePaymentStatus ] = useState(false);
  const [ openCredpalModal, setOpenCredpalModal ] = useState(false);
  const [payStackKey, setPayStackKey] = useState(null);
  const storageUser = localStorage.getItem("user_customer_details");
  const user = storageUser ? JSON.parse(storageUser) : null;
  const { orderdetails } = useSelector((state) => state.Orders);
  const [addOrders] = useMutation(ADD_ORDERS);

  
  useEffect(()=>{
    const { search} = location;
    if(search !== ""){
      const searchQuery = search.split("=")[1];
      setSearchParams(searchQuery);
    }
  }, [location]);

  const getMerchantPayStackDetails = async () => {
    const merchant = localStorage.getItem("urewards_merchant_details");
    if (merchant) {
      setgettingPayStack(true);
      const merch = JSON.parse(merchant);
      const merchant_email = merch.email;
      await axios
        .post(
          `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_paystack_details`,
          { merchant_email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setgettingPayStack(false);
            setPayStackKey(response.data.data.data);
          } else {
            setgettingPayStack(false);
            setPayStackKey(null);
          }
        })
        .catch((err) => {
          setgettingPayStack(false);
          setPayStackKey(null);
          message.error(`${err.response.statusText}`);
        });
    }
  };

  useEffect(() => {
    isPayStack && getMerchantPayStackDetails();
  }, []);

  const totalOrder = (cart) => {
    if (cart && cart.length === 0 ) return 0;
    const cartItemWithVariants = cart.filter((item)=>{
      return item.variants && item.variants.length > 0;
    });

    const cartItemsWithCust = cart.filter((item)=>{
      return item.custom_quantities && item.custom_quantities.length > 0;
    });

    const cartBundle = cart.filter((item)=>{
      return item.bundle_quantity && typeof item.bundle_quantity === "object";
    });

    const normItem = cart.filter((item)=>{
      return item.variants && item.variants.length === 0 && item.custom_quantities && item.custom_quantities.length === 0;
    });
    
    const totalVaraiant = cartItemWithVariants.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.variants[0].price));
    }, 0);

    const totalCust = cartItemsWithCust.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].price));
    }, 0);

    const totalBundle = cartBundle.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.price));
    }, 0);

    const otherTotal = normItem.reduce((prev, curr) => {
      return prev + Number(curr.quantity) * Number(curr.price);
    }, 0); 

    return otherTotal + totalCust + totalVaraiant + totalBundle;
  };

  const totalStackOrder = (cart) => {
    if (cart.length === 0) return 0;
    const cartItemWithVariants = cart.filter((item) => {
      return item.variants && item.variants.length > 0;
    });

    if (cartItemWithVariants.length === 0) {
      return (
        cart.reduce((prev, curr) => {
          return Math.round(prev + curr.quantity * curr.price);
        }, 0) * 100
      );
    } else {
      const cartItemNotWithVariants = cart.filter((item) => {
        return item.variants === undefined || item.variants.length === 0;
      });

      const itemNotVariantsTotal = cartItemNotWithVariants.reduce(
        (prev, curr) => {
          return Number(prev) + Number(curr.quantity) * Number(curr.price);
        },
        0
      );

      const itemVariantsTotal = cartItemWithVariants.reduce((prev, curr) => {
        return (
          Number(prev) + Number(curr.quantity) * Number(curr.variants[0].price)
        );
      }, 0);

      return (itemNotVariantsTotal + itemVariantsTotal) * 100;
    }
  };

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: merchant.email,
    currency: "NGN",
    amount: deliveryPrice ? totalStackOrder(carts)+(Number(deliveryPrice) * 100) : carts && totalStackOrder(carts),
    publicKey: deliveryPrice 
    ? process.env.REACT_APP_PAYSTACK_PUBLIC_KEY 
      : payStackKey 
        ? payStackKey
        : process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  };

  useEffect(() => {
    setEmail(email);
  }, [email]);

  useEffect(() => {
    setDeliveryAddress(deliveryAddress);
  }, [deliveryAddress]);

  useEffect(() => {
    dispatch(getMerchantByDispatch());
  }, []);

  const handleSetDelivery = () => {
    if (
      email === "" ||
      address_line1 === "" ||
      address_line2 == "" ||
      state === "" ||
      country === ""
    ) {
      return message.info("Please fill all fields");
    }

    if (isGiftCard) {
      onClickGiftCard();
    } else if (isUSSD) {
      onClickUSSD();
    } else if (isInStore) {
      onClickTill();
    }
    setShowDelivery(false);
  };

  const handlePayStack = usePaystackPayment(paystackConfig);

  const paymentCallback = (resp) => {
    if (resp.message === "Approved") {
      setShowInStoreOrders(true);
      setPayStackLoading(false);
      setShowPayStack(false);
      onCancel();
      addOrders({
        variables: { data: orderdetails },
        onCompleted: (respo) => {
          setPayStackLoading(false);
          setShowPayStack(false);
          onCancel();
          handlePostSales(orderdetails, "card");
        },
        onError: (err) => {
          setPayStackLoading(false);
          Modal.error({
            title: `${err.message}, pls try again`,
          });
        },
      });
      paywithDelivery && merchantDetails.currency === "NGN" && sendDeliveryRequest();
    } else {
      Modal.error({
        title: `payment not successful, please try again!`,
      });
    }
  };

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const convertCartsToParams = (carts) => {
    
    const lineItems = carts.map((cartItem)=>{
      const { price, quantity, variants } = cartItem;

      const priceData = {
        price_data: {
          currency: merchant.currency,
          product_data: {
            ...cartItem,
            price: Number(price)
          },
          unit_amount: variants && variants.length > 0 ? Number(variants[0].price) * 100 : Number(price) * 100,
        },
        quantity: quantity,
      }
      return priceData;
    })
    
    const params = {
      amount_total: totalOrder(carts) * 100,
      total_amount: totalOrder(carts) * 100,
      merchant_id: merchantDetails.id,
      line_items: lineItems,
      success_url: `/cart?payment=successful `,
      cancel_url: `/cart?payment=cancelled`,
    };
    return params;
  };
  const params = convertCartsToParams(carts);

  const sendParamsToBackend = (params) => {
    setIsstripeLoading(true);
    const subUrl = "/merchant/stripe_checkout_session";
    const URL = `${baseUrl}${subUrl}`;

    axios
      .post(URL, {
        data: params,
      })
      .then((response) => {
        // console.log("Response from backend:", response.data);
        // open a new tab with the Fcheckout session
        window.open(response.data.url, "_blank");
        setIsstripeLoading(false);
      })
      .catch((error) => {
        console.error("Error sending data to backend:", error);
        message.error("An error occurred while processing your request.");
        setIsstripeLoading(false);
      });
  };

  // useEffect(() => {
  //   const { search} = location;
  //   if(search !== ""){
  //     const searchQuery = search.split("=")[1];
  //     if (searchQuery === "successful") {
  //       message.success("Payment confirmed")
  //       setStripePaymentStatus(true);
  //       handleStripePayment();
  //     } else if (searchQuery === "cancelled") {
  //       message.error("Unable to process your stripe payment, please try again")
  //       setStripePaymentStatus(false);
  //     }
  //   }
  // }, [location]);
  const total = deliveryPrice ? totalOrder(carts)+Number(deliveryPrice) : totalOrder(carts)

  return (
    <>
      <Modal
        open={open}
        // open={true}
        onCancel={onCancel}
        className="checkout_modal1"
        // onOk={()=>handleCancelOrder()}
        footer={null}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              padding: "0 1rem",
              lineHeight: "24px",
            }}
          >
            How would you like to pay
          </span>
          <div style={{ height: "15px" }}></div>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "600",
              opacity: "0.6",
            }}
          >
            {`Total due${deliveryPrice ? " (+delivery)" : ""}:`}
          </span>
          {loading ? <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><Spin size="small" indicator={<LoadingOutlined style={{fontSize: 20}} spin />} /></div>:<span
            style={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "38.73px",
            }}
          >
            {merchantDetails &&
              merchantDetails?.currency + total.toFixed(2)}
             {/* {deliveryPrice ? <span style={{fontSize:12}}>(+delivery fee)</span> : null}  */}
          </span>}
        </div>
        <div style={{ height: "10px" }}></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          {/*paywithDelivery === false &&   */}
          {(!deliveryPrice && <Button
              className="cart_buttons"
              onClick={() => onClickTill()}
              type="primary"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Pay at Collection</span>
                {/* <span
                    style={{
                        fontSize: "10px",
                        lineHeight: "15px",
                        opacity: "0.6"
                    }}
                >(Pay at Collection)</span> */}
              </div>
            </Button>
          )}
          {merchant?.currency === "NGN" && (
            <Button
              className="cart_buttons"
              onClick={() => onClickUSSD()}
              type="primary"
              style={{
                display: `${merchant?.payout_active ? "block" : "none"}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Pay with USSD</span>
              </div>
            </Button>
          )}
          {isStripeEnabled && merchant?.currency === "GBP" && (
            <Button
              className="cart_buttons"
              onClick={() => {
                // if(stripePaymentStatus){
                //   // handleStripePayment();
                // }else{
                  !stripePaymentStatus && sendParamsToBackend(params);
                // }
              }}
              type="primary"
              loading={isStripeLoading || getInStoreLoading}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Pay Now</span>
              </div> 
            </Button>
          )}
          {isPayStack &&  merchant?.currency === "NGN" && (
            <Button
              className="cart_buttons"
              onClick={() => {
                setPayStackLoading(true);
                handlePayStackPayment(carts, user, totalStackOrder(carts));
                handlePayStack(paymentCallback, () => {});
              }}
              loading={gettingPayStack}
              type="primary"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <span>Pay with Paystack</span> */}
                <span>Make Payment</span>
              </div>
            </Button>
          )}
          {isCredPalEnabled && (
            <Button
              className="cart_buttons"
              onClick={()=>setOpenCredpalModal(true)}
              // loading={gettingPayStack}
              type="primary"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <span>Pay with Paystack</span> */}
                <span>Pay with Credit</span>
              </div>
            </Button>
          )}
          {giftcard !== null && (
            <Button className="cart_buttons" onClick={() => onClickGiftCard()}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Spend GiftCard</span>
              </div>
            </Button>
          )}
          <p className="cart_action" onClick={onCancel}>
            Cancel
          </p>
        </div>
      </Modal>
      <ConfirmModal
        open={showPayStack}
        onCancel={() => setShowPayStack(false)}
        onOk={() => {
          setPayStackLoading(true);
          handlePayStack(paymentCallback, () => {});
        }}
        body="Proceed to pay with Paystack"
        loading={PayStackLoading}
      />
      {openCredpalModal 
        && 
        <CredpalProcessModal 
          open={openCredpalModal}
          onCancel={()=> setOpenCredpalModal(false)}
          totalAmount={() => totalOrder(carts)}
          setOrderDetails={setOrderDetails}
          cancelAsk={onCancel}
        />
      }
      <Modal
        open={showDelivery}
        onCancel={() => setShowDelivery(false)}
        title="Update Address"
        onOk={() => handleSetDelivery()}
        destroyOnClose
      >
        <Input
          placeholder="Email address"
          value={email}
          name="email"
          onChange={(e) => {
            const { name, value } = e.target;
            setEmail(value);
            setDeliveryDetails((prev) => {
              return {
                ...prev,
                [name]: value,
              };
            });
          }}
          type="email"
        />
        {/* <div style={{height: "10px"}}></div>
    <Input 
        placeholder="Delivery address"
        value={deliveryAddress}
        name="deliveryAddress"
        onChange={(e)=>{
            const { name, value } = e.target;
            setDeliveryAddress(value);
            setDeliveryDetails((prev)=>{
                return {
                    ...prev,
                    [name]: value
                }
            })
        }}
        type="text"
    /> */}
        <div style={{ height: "10px" }}></div>
        <Input
          placeholder="address_line1"
          value={address_line1}
          name="address_line1"
          onChange={(e) => setAddress_line1(e.target.value)}
          type="text"
        />
        <div style={{ height: "10px" }}></div>
        <Input
          placeholder="address_line2"
          value={address_line2}
          name="address_line2"
          onChange={(e) => setAddress_line2(e.target.value)}
          type="text"
        />
        <div style={{ height: "10px" }}></div>
        <Input
          placeholder="state"
          value={state}
          name="state"
          onChange={(e) => setState(e.target.value)}
          type="text"
        />
        <div style={{ height: "10px" }}></div>
        <Input
          placeholder="country"
          value={country}
          name="country"
          onChange={(e) => setCountry(e.target.value)}
          type="text"
        />
        <div style={{ height: "10px" }}></div>
        <Input
          placeholder="postal_code"
          value={postal_code}
          name="postal_code"
          onChange={(e) => setPostal_code(e.target.value)}
          type="text"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  carts: state.carts,
  merchantDetails: state.merchantDetails,
  orderdetails: state.orderdetails,
  urewardsAuth: state.urewardsAuth,
  merchantState: state.merchantState,
});
export default connect(mapStateToProps)(AskPayment);
