import * as Types from "../actions/types";


const urewardCarts = JSON.parse(localStorage.getItem("urewards-carts"));

const initialState = {
    loading: false,
    carts:  urewardCarts ? urewardCarts : []
}

export const cartReducer = ( state = initialState, {type, payload}) =>{
    switch (type) {
        case Types.ADD_TO_CART: {
            
            const item = payload;
            if(item.variants && item.variants.length > 0){
                const isItemExists = state.carts.filter(i=> i.id === item.id);
                if(isItemExists.length !== 0){
                    const variantItem = isItemExists.filter((ite)=>{
                        return ite.variants[0].id === item.variants[0].id;
                    });
                    if(variantItem.length === 0){
                        
                        state.loading = false;
                        state.carts = [...state.carts, item]
                    }else{
                        // const notVariant = isItemExists.filter((item)=>{
                        //     return item.variants[0].id !== item.variants[0].id;
                        // });    
                        // const isItemNotExists = state.carts.filter(i=> i.id !== item.id);
                        state.loading = false;
                        state.carts = state.carts.map((it)=>{
                            if(it.variants.length !== 0){
                                if(it.variants[0].id === item.variants[0].id){
                                    return item;
                                }else{
                                    return it;
                                }
                            }else{
                                return it;
                            }
                        })
                    }
                }else{
                  state.loading = true;
                  state.carts = [...state.carts, item]
                }
            }else if(item.custom_quantities && item.custom_quantities.length > 0){
                const isItemExists = state.carts.filter(i=> i.id === item.id);
                if(isItemExists.length !== 0){
                    const customItem = isItemExists.filter((ite)=>{
                        return ite.custom_quantities[0].id === item.custom_quantities[0].id;
                    });
                    if(customItem.length === 0){
                        state.loading = false;
                        state.carts = [...state.carts, item]
                    }else{
                        // const notCustom = isItemExists.filter((item)=>{
                        //     return item.custom_quantities[0].id !== item.custom_quantities[0].id;
                        // });    
                        // const isItemNotExists = state.carts.filter(i=> i.id !== item.id);
                        state.loading = false;
                        state.carts = state.carts.map((it)=>{
                            if(it.custom_quantities && it.custom_quantities.length !== 0){
                                if(it.custom_quantities[0].id === item.custom_quantities[0].id){
                                    return item;
                                }else{
                                    return it;
                                }
                            }else{
                                return it;
                            }
                        })
                    }
                }else{
                  state.loading = true;
                  state.carts = [...state.carts, item]
                }
            }else {
                const isItemExists = state.carts.find(i=> i.id === item.id);
                if(isItemExists){
                    state.loading = false;
                    state.carts = state.carts.map(i=> i.id === isItemExists.id ? item : i)
                }else{
                    state.loading = true;
                    state.carts = [...state.carts, item]
                }
            }
            
            localStorage.setItem("urewards-carts", JSON.stringify(state.carts));
            return state;
        }
        case Types.REMOVE_FROM_CART: {
            state.loading = false;
            state.carts = state.carts.filter((i) => {
                if(payload.variants&&payload.variants.length > 0 && i.variants.length > 0){
                    return i.variants[0].id !== payload.variants[0].id
                }else if(payload.custom_quantities && payload.custom_quantities.length > 0 && i.custom_quantities.length > 0){
                    return i.custom_quantities[0].id !== payload.custom_quantities[0].id;
                }else{
                    return i.id !== payload.id;
                }
            });
            localStorage.setItem("urewards-carts", JSON.stringify(state.carts));
            return state;
        }
        case Types.CLEAR_CART:{
            state.loading = false;
            state.carts = []
            localStorage.removeItem("urewards-carts");
            localStorage.removeItem("ureward_orderDetails");
        }
        default: {
			return {
				...state,
			};
		}
    }
}

const initialState2 = {
    loading: false,
    merchantDetails: localStorage.getItem("merchant_details") ? JSON.parse(localStorage.getItem("merchant_details")) : {}
}

export const merchantreducer = ( state = initialState2, {type, payload} ) =>{
    switch (type) {
        case Types.MERCHANT_ORDER_DETAILS: {
            state.loading = false;
            state.merchantDetails = payload;
            localStorage.setItem("urewards_merchant_details", JSON.stringify(payload));
            return state;
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const getOrderSelectors = ( state ) => state.Order;