import React, { useEffect, useState } from 'react';
import Header from '../../reusables/Header';
import Footer from '../../reusables/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { JayAutos, Rectangle } from '../../assets/icons';
import { Card, message, Button, Modal, Image } from 'antd';
import DetailsModal from './modals/detailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearCart, addServicesToCart } from '../../redux/actions/orders';
import SelectDate from './modals/selectDate';
import { useMutation, useQuery } from '@apollo/client';
import PaymentOptions from './modals/paymentOptions';
import { CREATE_BOOKING, FETCH_MERCHANT_DETAILS } from '../../constants';
import { getMerchantApp } from '../../redux/actions/merchant';
import { getMerchatLoyalty, getUrewardsAuth } from '../../redux/actions/ussdActions';
import SigninModal from '../homepage/partials/SigninModal';
import SuccessModal from '../cart/modal/successModal';
import BookingSuccess from './modals/bookingSuccess';
import { getMerchantBranches } from '../../redux/actions/branch';

const Booking = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [ newUser, setNewUser ] = useState(null);
    const [ merchant, setMerchant ] = useState({});
    const [ services, setServices ] = useState([]);
    const [ onlineService, setOnlineServices ] = useState([]);
    const [ selectedService, setSelectedServices ] = useState([]);
    const [ selectedServiceId, setSelectedServicesId ] = useState([]);
    const [ serviceLoading, setServiceLoading ] = useState(false);
    const [checkDescription, setCheckDescription] = useState(false);
    const [ merchantBranches, setMerchantBranches ] = useState([]);
    const [ branchLoading, setBranchLoading ] = useState(false);
    const [description, setDescription] = useState({});
    const [askCart, setAskCart] = useState(false);
    const [ showDatePicker, setShowDatePicker ] = useState(false);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ branchStaff, setBranchStaff ] = useState([]);
    const [ branchStaffLoading, setBranchStaffLoading ] = useState(false);
    const [ selectedStaff, setSelectedStaff ] = useState(null);
    const [ hasBookDate, setHasBookDate ] = useState(false);
    const [ repeat, setRepeat ] = useState(false);
    const [ repeat_frequency, setRepeat_frequency ] = useState(null);
    const [ repeat_number, setRepeat_number ] = useState(null);
    const [ openPaymentOption, setOpenPaymentOption ] = useState(false);
    const [ isPaymentSuccessful, setIsPaymentSuccessful ] = useState(false);
    const [ lastIndex, setLastIndex ] = useState(null);
    const [ displayCondition, setDisplayCondition ] = useState(null);
    const [ serviceTotal, setServiceTotal ] = useState(null);
    const [ openSuccessModal, setOpenSuccessModal ] = useState(false);
    const [ user, setUser ] = useState(null);
    const [ workHours, setWorkHours ] = useState([]);
    const [ gettingWorkHours, setGettingWorkHours ] = useState(false);
    const [ merchantEmail, setMerchantEmail ] = useState(null);
    const [ selectedBranch, setSelectedBranch ] = useState(null);
    const [ branchId, setBranchID ] = useState({branch_id: null,
        branch_name: null
    })
    const [ branchAddress, setBranchAddress ] = useState(null);
   
    const {
        uRewardsAuth,
        status,
        ussdOrderSuccess,
        merchantloyaltySuccess,
        giftcard,
    } = useSelector((state) => state.urewardsAuth);
   

    const [createBooking, { loading: isCreateBookingLoading }] = useMutation(
        CREATE_BOOKING,
        {
          onCompleted: data => {
            // form.resetFields();
            // Modal.success({
            //     title: "Booking created successfully",
            // });
            if(lastIndex === selectedService.length - 1){
                setOpenSuccessModal(true);
            }
          },
          onError: error => {
            lastIndex === selectedService.length - 1 && Modal.error({
                title: error.message,
            });
          },
        //   refetchQueries: [{ query: GET_BOOKINGS }],
        }
    );

    const cancelAll = () => {
        setSelectedServices([]);
        setSelectedServicesId([]);
        setHasBookDate(false);
        setSelectedDate(null);
        setSelectedStaff(null);
        setOpenPaymentOption(false);
    }

    const { loading: merchantDetailsLoading } =
    useQuery(
      FETCH_MERCHANT_DETAILS,
      {
        variables: { uRewardsId: history.location.pathname.split("/")[1] },
        // skip: !!merchant_id,
        onCompleted: (data) => {
          data.fetchMerchantDetails === "Merchant not found" && history.push("/404");
          const merchanDet = JSON.parse(data.fetchMerchantDetails);

          const branches = merchanDet.merchant_details.business_branches;

          setMerchant(merchanDet.merchant_details);
          localStorage.setItem("merchant_details",JSON.stringify(merchanDet.merchant_details));

          const homeBranch = branches.filter((branch) => {
            return branch.name.toLowerCase().includes("home");
          });

          const branchpayload = {
            isbranchSet: true,
            address_line1: homeBranch[0].address_line1,
            address_line2: homeBranch[0].address_line2,
            branch_id: homeBranch[0].id,
            branch_name: homeBranch[0].name
          };

          getMerchantApp(merchanDet.merchant_details.email);
          setMerchantEmail(merchanDet.merchant_details.email);
          getMerchantStaffDetails(merchanDet.merchant_details.email, homeBranch[0].id);
          getMerchantServices(merchanDet.merchant_details.id);
          getMerchantWorkHours(merchanDet.merchant_details.id)

          localStorage.setItem("branchdetails", JSON.stringify(branchpayload));

          localStorage.setItem("homebranch", JSON.stringify(homeBranch));
        },
      },
      {
        onError: (error) => {
          message.error(error.message);
        },
      }
    );

    useEffect(() => {
        const branch = location.state ? location?.state?.selectedBranchDetails : null

        if(branch){
            setSelectedBranch(String(branch.id))
            setBranchAddress(() => {
                return `${branch.address_line1 ? branch.address_line1 : ""}${branch.address_line2 ? ", " +branch.address_line2 : ""}`
            })
        }
        // console.log("br", branch);
       
    }, []);

    useEffect(() => {
        const merchants = JSON.parse(localStorage.getItem("merchant_details"));
        if (merchants?.payout_active) {
          if (status !== "success") {
            const email = merchants?.email || merchant?.email;
            const location = window.location.pathname;
            dispatch(getUrewardsAuth({ email, location }));
          }
        }
        // if(status)
    }, [status]);

    useEffect(()=>{
        const isUser = localStorage.getItem("user_customer_details");
        if(isUser) setUser(JSON.parse(isUser));
    }, [newUser]);

    useEffect(() => {
        !!newUser &&
            localStorage.setItem("user_customer_details", JSON.stringify(newUser));
    }, [newUser]);

    useEffect(() => {
        const merchants = JSON.parse(localStorage.getItem("merchant_details"));
        if (merchants?.payout_active) {
          if (merchantloyaltySuccess) return;
          if (status === "success") {
            const { client, access_token, uid, email } = uRewardsAuth;
            const headers = {
              client: uRewardsAuth.client,
              "access-token": uRewardsAuth.access_token,
              uid: uRewardsAuth.uid,
            };
            dispatch(getMerchatLoyalty(headers));
          }
        }
    }, [dispatch, status, merchantloyaltySuccess, uRewardsAuth]);


    const getMerchantApp = async ( email ) => {
        await Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/urewards_merchant_app`, { merchant_email: email }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then((response)=>{
            if(response.status === 200){
                const apps = response.data.data;

                const services = apps.filter((app)=>{
                    return app.app.app_name === "Services, Bookings & Appointments"
                });

                const url = history.location.pathname.split("/")[1];
                services.length === 0 && history.push(`/${url}`);
            }
        })
        .catch((error)=>{
            return error.message;
        })
    }


    const getMerchantServices = async (id) => {
        const merchant_id  = id;
        setServiceLoading(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_services`, { merchant_id })
        .then((res)=>{
            setServiceLoading(false);
            setServices(res.data.services);
            setOnlineServices(()=>{
                return res.data.services.filter(serv => serv.online_booking === "Enabled")
            })
        })
        .catch((err)=> {
            setServiceLoading(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const getMerchantWorkHours = async (id) => {
        const merchant_id  = id;
        setGettingWorkHours(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_work_hours`, { merchant_id })
        .then((res)=>{
            setGettingWorkHours(false);
            setWorkHours(res.data.workHours);
        })
        .catch((err)=> {
            setGettingWorkHours(false);
            console.log(err);
            message.error("unable to fetch services, please try again")
        })
    }

    const handleSelectPaymentType = () => {

        const storageUser = localStorage.getItem("user_customer_details");
        const user = storageUser ? JSON.parse(storageUser) : null;
        const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
        const merchants = JSON.parse(localStorage.getItem("merchant_details")); 
        const branch_id  = branchDetails.branch_id;
        const branch_name  = branchDetails.branch_name;
        const merchant_id = merchants.id;
        const merchant_email = merchants.email;
        const first_name = merchants.first_name;

        if (!user) {
            message.error("Sign in to add products to your cart");
            return setSignInModalVisible(true);
        }

        localStorage.setItem("serviceItems", JSON.stringify(selectedService));

        setOpenPaymentOption(true);
    }

    const getMerchantStaffDetails = async (merchant_email, branch_id) => {  
        setBranchStaffLoading(true);
        Axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/get_merchant_branch_staffdetails`, { merchant_email, branch_id })
        .then((res)=>{
            setBranchStaffLoading(false);
            setBranchStaff(res.data.data);
            setMerchantBranches(res.data.branches)
        })
        .catch((err)=> {
            setBranchStaffLoading(false);
            console.log(err);
            // message.error("unable to fetch services, please try again")
        })
    }

    const handleCancelOrder = () => {
        dispatch(clearCart());
        setAskCart(false);
    };

    const handleSelectService = (services) => {
        const newService = {...services};
        let quantity;
        if(!services.quantity){
            newService.quantity = 1;
            quantity = 1
        }else{
            newService.quantity = Number(services.quantity) + 1;
            quantity = Number(services.quantity) + 1;
        }


        const isSelectedServices = selectedService.find(service => service.id === services.id);

        if(isSelectedServices){
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })
        }else{
            setSelectedServices((prev)=>{
                return [...prev, newService]
            })
        }

        setServices((prev)=>{
            return [
                ...prev.map((servic)=>{
                    if(servic.id === services.id){
                        servic.quantity = quantity;
                        return servic;
                    }else{
                        return servic;
                    }
                })
            ]
        })
    } 

    const handleSelectServiceDecrease = (services) => {
        const newService = {...services};
        let quantity;

        if(services.quantity <= 1){
            setSelectedServicesId((prev)=>{
                return [
                    ...prev.filter((serv)=>serv !== services.id)
                ]
            })

            setSelectedServices((prev)=>{
                return [
                    ...prev.filter((serv)=>serv.id !== services.id)
                ]
            })

            setServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            delete servic.quantity;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
            
        }else{
            let quantity = services.quantity;
            setSelectedServices((prev)=>{
                return [
                    ...prev.map((serv)=>{
                        if(serv.id === services.id){
                            serv.quantity = quantity - 1;
                            return serv;
                        }else{
                            return serv;
                        }
                    })
                ]
            })

            setServices((prev)=>{
                return [
                    ...prev.map((servic)=>{
                        if(servic.id === services.id){
                            servic.quantity = quantity - 1;
                            return servic;
                        }else{
                            return servic;
                        }
                    })
                ]
            })
        }
    }

    const calculateTotal = () => {
        const total = selectedService.reduce((prev, curr)=> {
            return prev + (Number(curr.amount_charged) * Number(curr.quantity))
        }, 0);
        setServiceTotal(total);
        return total;
    }

    useEffect(()=>{
        calculateTotal();
    }, [selectedService])

    const handleCreateBooking = () => {
        const storageUser = localStorage.getItem("user_customer_details");
        const user = storageUser ? JSON.parse(storageUser) : null;
        const branchDetails = JSON.parse(localStorage.getItem("branchdetails"));
        const merchants = JSON.parse(localStorage.getItem("merchant_details")); 
        const branch_id  = branchDetails.branch_id;
        const branch_name  = branchDetails.branch_name;
        const merchant_id = merchants.id;
        const merchant_email = merchants.email;
        const merchant_name = merchants.business_name;
        const first_name = merchants.first_name;

        if (!user) {
            message.error("Sign in to add products to your cart");
            return setSignInModalVisible(true);
        }

        selectedService.map((servi, index) => {
            const completion_time = servi.completion_time || servi.duration;
            setLastIndex(index)
            const service_id = servi.id;
            delete servi.image_url;
            delete servi.location;
            delete servi.id;
            delete servi.description;
            delete servi.online_booking;
            delete servi.deleted_at;
            delete servi.created_at;
            delete servi.duration;
            delete servi.updated_at;
            delete servi.service_type;
            delete servi.quantity;

            const createBookingObj = {
                ...servi,
                customer_id: Number(user.user_id),
                service_id,
                ...selectedStaff,
                repeat,
                repeat_frequency,
                repeat_number, 
                booking_date: selectedDate.format("DD-MM-YYYY   hh:mma"),
                completion_time,
                branch_id: branchId.branch_id,
                branch_name: branchId.branch_name,
                merchant_id,
                merchant_email,
                first_name,
                merchant_name,
                payment_status: isPaymentSuccessful ? "paid" : "not paid"
            }
            // console.log(createBookingObj);
            return createBooking({ variables: { data: createBookingObj } });
        });


    }

    const handleAddServicesToCart = (services) => {
        const storageUser = localStorage.getItem("user_customer_details");
        const merchants = JSON.parse(localStorage.getItem("merchant_details")); 
        const user = storageUser ? JSON.parse(storageUser) : null;
        const serviceToPurchase = {...services};

        if (!user) {
            message.error("Sign in to add products to your cart");
            return setSignInModalVisible(true);
        }

        const cart_items = JSON.parse(localStorage.getItem("urewards-carts"));

        const new_cart =
          cart_items &&
          cart_items.find((items) => {
            return items.merchant_id !== merchants.id;
        });

        if (new_cart) {
            message.warning(`Check out from previous merchant to proceed to cart`);
            return setAskCart(true);
        }

        const isItemInCart = cart_items && cart_items.find(item => item.id === services.id);

        serviceToPurchase.merchant_id = merchant.id;
        if(isItemInCart){
            serviceToPurchase.quantity = Number(isItemInCart.quantity) + 1;
        }else{
            serviceToPurchase.quantity = 1;
        }

        dispatch( addServicesToCart(serviceToPurchase) );

        toast.success(
            `Successfully added ${serviceToPurchase ? serviceToPurchase.service_name : "Item"} to Cart`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
        )


        const basket = document.querySelector("#cartBasket");
        basket.classList.add("cart_animate");
        setTimeout(() => {
        basket.classList.remove("cart_animate");
        }, 700);
    }

    useEffect(()=>{
        const displayServiceCondition = window.matchMedia("(max-width: 768px)").matches && selectedService.length === 0;
        setDisplayCondition(displayServiceCondition);
    }, [selectedService, window.matchMedia("(max-width: 768px)").matches])


    window.addEventListener("scroll", (e)=>{
        const totall = document.querySelector("#serviceviewtotal");
        if (window.matchMedia("(max-width: 768px)").matches) {
            totall && totall.classList.add("fixedPosition");
        } else {
            if(window.pageYOffset >= 472){
                totall && totall.classList.add("fixedPosition");
            }else{
                totall && totall.classList.remove("fixedPosition")
            }
        }
    })
  return (
    <>
        <Header 
            openLoginModal={() => setSignInModalVisible(true)}
            titleComponent={
            <h2 className="title_text" style={{ margin: 0 }}>
                {merchant && merchant?.business_name || "Merchant"}
            </h2>
            }
            // merchantLink={history.location.pathname.split("/")[1]}
            merchantee
        />
        <div className='container_main' id='contain_servicge'>
        <Card
             bordered={false}
             bodyStyle={{ padding: 0 }}
             loading={serviceLoading || merchantDetailsLoading}
             style={{ backgroundColor: "#000", border: "none" }}
        >
            <div className="flex_center_column">
                {merchant && <div
                    className="flex_center_column"
                    style={{
                        background: `url(${
                        merchant?.cover_image || JayAutos
                        }) center center/cover no-repeat`,
                        width: "100%",
                        height: "400px",
                        backgroundSize: "cover",
                        // backgroundRepeat: "no-repeat",
                    }}
                >
                    <div
                        style={{
                        textAlign: "center",
                        margin: "4rem 1.5rem 0",
                        }}
                    >
                        <h3 className="title_text" style={{ marginBottom: 0 }}>
                            {merchant?.business_name}
                        </h3>
                        <p
                            style={{ marginTop: 10, fontSize: "14x", lineHeight: "150%" }}
                        >
                            {merchant?.business_type && merchant?.business_type}
                        </p>
                        <p
                            style={{
                                marginTop: 10,
                                fontSize: "14px",
                                lineHeight: "150%",
                            }}
                        >
                            {merchant?.description}
                        </p>
                        {branchAddress ? <p style={{color: "#fff"}}>{branchAddress}</p> : <p
                            style={{
                                marginTop: 10,
                                fontSize: "14px",
                                lineHeight: "150%",
                            }}
                        >
                            {merchant?.address_line1}
                            {merchant?.address_line2
                                ? ", " + merchant?.address_line2
                                : ""}
                            {merchant?.state ? ", " + merchant?.state : ""}
                            {merchant?.country ? " ," + merchant?.country : ""}
                        </p>}
                    </div>
                </div>}
                <hr style={{ width: "100%" }} />
                <div style={{width: "100%"}}>
                    <h2 style={{ textAlign: "center", textTransform: "uppercase"}}>Select Services</h2>
                    
                    <div className="serviceview">
                        <div className="serviceviewservice">
                            {services.length > 0 ? services.map((product, index) => (
                                // product.online_booking === "Enabled" && 
                                <div 
                                    key={index} 
                                    className="serviceinitial"
                                    onClick={() => {
                                        setDescription(product);
                                        setCheckDescription(true);
                                    }}   
                                >   
                                    <div className='servicefes'>
                                        <Image 
                                            src={product.image_url || Rectangle} 
                                            alt={product.service_name}
                                        />
                                    </div>
                                    <div className='services'>
                                        <h3>{product.service_name}</h3>
                                        <div className="servicebelow">
                                            <p>{product.completion_time || product.duration} mins | {Number(product.amount_charged).toFixed(2)}</p>
                                            <div style={{zIndex: 1}}>
                                                {selectedServiceId.includes(product.id) ? 
                                                    <div className="plusandminus">
                                                        <Button
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                handleSelectServiceDecrease(product)}
                                                            }
                                                        >
                                                            -
                                                        </Button>
                                                        <p style={{marginBottom: "0"}}>{product.quantity}</p>
                                                        <Button
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                handleSelectService(product)}
                                                            }
                                                        >
                                                            +
                                                        </Button>
                                                    </div>
                                                :
                                                <Button
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    const storageUser = localStorage.getItem("user_customer_details");
                                                    const user = storageUser ? JSON.parse(storageUser) : null;
                                                    if (!user) {
                                                        message.error("Sign in to add products to your cart");
                                                        return setSignInModalVisible(true);
                                                    }
                                                    // setCheckDescription(false);
                                                    setSelectedServicesId((prev)=>{
                                                        return [...prev, product.id]
                                                    })
                                                    handleSelectService(product);
                                                }}
                                                className="selectbtn"
                                            >
                                                Select
                                            </Button>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            // <Col
                            //     span={12}
                            //     md={12}
                            //     lg={8}
                            //     key={index}
                            //     style={{
                            //     height: "300px",
                            //     // display: product.publish_to_loystar_shop ? "initial" : "none"
                            //     }}
                            // >
                            //     <div
                            //     className="flex_center_column"
                            //     onClick={() => {
                            //         setDescription(product);
                            //         setCheckDescription(true);
                            //     }}
                            //     style={{
                            //         backgroundImage: product.image_url
                            //         ? `url(${product.image_url})`
                            //         : `url(${Rectangle}) `,
                            //         height: "90%",
                            //         width: "90%",
                            //         margin: "20px 10px",
                            //         justifyContent: "space-between",
                            //         backgroundSize: "cover",
                            //         backgroundRepeat: "no-repeat",
                            //         backgroundPosition: "center",
                            //     }}
                            //     >
                            //     <div
                            //         style={{
                            //         display: "flex",
                            //         justifyContent: "flex-end",
                            //         padding: 20,
                            //         width: "100%",
                            //         }}
                            //     >
                            //         {/* <img src={LoveIcon} alt="favourite" style={{backgroundSize: "cover", backgroundRepeat: "no-repeat"}}/> */}
                            //     </div>
                            //     <div
                            //         style={{
                            //         display: "flex",
                            //         flexDirection: "column",
                            //         justifyContent: "space-between",
                            //         height: "120px",
                            //         background: "rgb(0, 0, 0, 0.3)",
                            //         width: "100%",
                            //         padding: "20px",
                            //         }}
                            //     >
                            //         <div
                            //         style={{
                            //             // float: "left",
                            //             color: "#fff",
                            //             display: "flex",
                            //             alignItems: "left",
                            //             justifyContent: "space-between",
                            //             margin: "-0.5rem -0.2rem 0 -0.5rem",
                            //             fontSize: "14px",
                            //             lineHeight: "14px",
                            //             gap: "0.3rem",
                            //             // zIndex: "1"
                            //         }}
                            //         >
                            //         <div 
                            //             style={{
                            //                 display: "flex", 
                            //                 flexDirection: "column",
                            //                 gap: "5px"
                            //             }}>
                            //             <b style={{ wordBreak: "break-all" }}>
                            //             {product.service_name}
                            //             </b>
                            //             <b 
                            //                 style={{ 
                            //                     wordBreak: "break-all",
                            //                     fontSize: "18px",
                            //                     color: "#8a8080" 
                            //                 }}>{product.service_type}</b>
                            //             </div>
                            //         <b>{Number(product.amount_charged).toFixed(2)}</b>
                            //         </div>
                            //     </div>
                            //     </div>
                            //     <p
                            //     style={{
                            //         float: "right",
                            //         color: "#fff",
                            //         textAlign: "right",
                            //         // paddingBottom: "3rem"
                            //         position: "absolute",
                            //         bottom: "1.3rem",
                            //         right: "1.7rem",
                            //     }}
                            //     >
                            //     <Button
                            //         type="link"
                            //         onClick={() =>handleAddServicesToCart(product)}
                            //         // disabled={product.variants.length !== 0}
                            //     >
                            //         <img src={addButton} alt="add" style={{ scale: "2" }} />
                            //     </Button>
                            //     </p>
                            // </Col>
                            )) : <h2>No service to display</h2>}
                        </div>
                        <div className="serviceviewtotal" id="serviceviewtotal"
                            style={{
                                display: displayCondition && "none"
                            }}
                        >
                            <h2 className="stitle">Service Summary</h2>
                            <hr style={{ width: "100%" }} />
                            {selectedService.length > 0
                                ? 
                                    <><div className="supDiv">
                                        {selectedService.map((service, index)=>{
                                            return (
                                                <div key={index} className="selectedServicess"
                                                >
                                                    <h3>{service.service_name}</h3>
                                                    <div className="lowerdiv">
                                                        <p>{service.completion_time || service.duration} mins | {Number(service.amount_charged).toFixed(2)}</p>
                                                        <p><b>{service.quantity}</b></p>
                                                    </div>
                                                    
                                                </div>
                                            ) 
                                        })}
                                    </div>
                                    </>
                                : <h3 style={{textAlign: "center"}}>No selected service</h3>
                            }
                            {selectedService.length > 0 
                                && 
                                <>
                                    <hr style={{ width: "100%" }} />
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}>
                                        <b style={{color: "#fff"}}>Total:</b>
                                        <p style={{margin: 0}}>{merchant.currency}{serviceTotal}</p>
                                    </div>
                                </>
                                }
                            <hr style={{ width: "100%" }} />
                            {selectedDate && <p><b>Date:</b> {selectedDate.format("DD-MM-YYYY   hh:mma")}</p>}
                            {selectedStaff && <p><b>staff:</b> {selectedStaff.staff_name} ({branchAddress})</p>}
                           {selectedDate && <hr style={{ width: "100%" }} />}
                            <div style={{
                                display: "flex", 
                                alignItems: "center", 
                                // flexDirection: hasBookDate ? "column" : "unset",
                                justifyContent: "center",
                                gap: "10px"
                                }}>
                                <Button 
                                    className="proceed"
                                    disabled={selectedService.length === 0}
                                    onClick={()=>setShowDatePicker(true)}
                                >
                                    {hasBookDate ? "Change date" : "Continue"}
                                </Button>
                                {hasBookDate 
                                    && 
                                    <Button 
                                        className="proceed"
                                        onClick={()=>handleSelectPaymentType()}
                                        loading={isCreateBookingLoading}
                                    >
                                        Book now
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                        
                   
                </div>
            </div>

        </Card>
        {checkDescription &&  
            <DetailsModal 
                open={checkDescription}
                onCancel={()=>setCheckDescription(false)}
                service={description}
                currency={merchant.currency}
            />
        }
        {showDatePicker && 
            <SelectDate 
                open={showDatePicker}
                onCancel={()=>setShowDatePicker(false)}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedStaff={selectedStaff}
                branchStaff={branchStaff}
                setSelectedStaff={setSelectedStaff}
                branchStaffLoading={branchStaffLoading}
                setHasBookDate={setHasBookDate}
                setRepeat={setRepeat}
                repeat={repeat}
                setRepeat_frequency={setRepeat_frequency}
                setRepeat_number={setRepeat_number}
                repeat_frequency={repeat_frequency}
                repeat_number={repeat_number}
                workHours={workHours}
                gettingWorkHours={gettingWorkHours}
                merchantBranches={merchantBranches}
                branchLoading={branchLoading}
                getMerchantStaffDetails={getMerchantStaffDetails}
                merchantEmail={merchantEmail}
                setBranchID={setBranchID}
                setBranchAddress={setBranchAddress}
                setSelectedBranch={setSelectedBranch}
                selectedBranch={selectedBranch}
            />
        }
        {askCart && (
            <Modal
                open={askCart}
                onCancel={() => setAskCart(false)}
                className="sure_modal"
                onOk={() => handleCancelOrder()}
                footer={[
                    <div>
                    <Button onClick={() => handleCancelOrder()}>Yes</Button>
                    <Button onClick={() => setAskCart(false)}>No</Button>
                    </div>,
                ]}
                >
                <h4>
                    Item(s) in cart have not been checkedout from previous merchant, do
                    you want to cancel order?
                </h4>
            </Modal>
        )}
        { openPaymentOption && (
            <PaymentOptions 
                open={openPaymentOption}
                onCancel={()=>setOpenPaymentOption(false)}
                payAtCollection={()=>handleCreateBooking()}
                total={serviceTotal}
                setIsPaymentSuccessful={setIsPaymentSuccessful}
                merchant={merchant}
                isCreateBookingLoading={isCreateBookingLoading}
            />
        )}
        {openSuccessModal &&
        <BookingSuccess 
            open={openSuccessModal}
            onCancel={()=>setOpenSuccessModal(false)}
            user={user}
            total={serviceTotal}
            bookedService={selectedService}
            merchant={merchant}
            word1={`WE HAVE RECEIVED YOUR BOOKING 📅`}
            cancelAll={()=>cancelAll()}
        />}
        
        </div>
        
        <Footer active="discover" disable isService/>
        <ToastContainer />
        {signInModalVisible && (
            <SigninModal
                visible={signInModalVisible}
                onCancel={() => setSignInModalVisible(false)}
                setNewUser={setNewUser}
            />
        )}
    </>
    
  )
}

export default Booking;